import React, { useMemo } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Container, Heading, Box, Image, Spinner } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import Main from '../../components/layouts/Main';
import usePrismicAboutUs from '../../hooks/usePrismicAboutUs';
import RichText from '../../components/PrismicRichText';

const Announcements = () => {
  const [id] = useQueryParam('id', StringParam);
  const data = usePrismicAboutUs();

//   console.log('Data from usePrismicAboutUs:', data);

  const trusteesboard = useMemo(() => {
    const foundBoard = data?.trusteesborads?.find((item) => item.id === id);
    // console.log('Trusteesboard found:', foundBoard);
    return foundBoard;
  }, [id, data]);

  if (!data) {
    return (
      <Main>
        <Container maxW="7xl" py="10" display="flex" justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Container>
      </Main>
    );
  }

  if (!trusteesboard) {
    return (
      <Main>
        <Container maxW="7xl" py="10">
          <Heading as="h1" fontSize="2xl"> </Heading>
        </Container>
      </Main>
    );
  }

//   console.log('Rendering trusteesboard:', trusteesboard);

  return (
    <Main>
      <Box overflow="hidden">
        <Container px="5" maxW="7xl">
          <Heading
            as="h1"
            fontSize={{ base: '2xl', sm: '3xl' }}
            fontWeight="900"
            mt={{ base: '3', sm: '5' }}
            mb={{ base: '1.875rem', sm: '10' }}
          >
            {trusteesboard.title}
          </Heading>
          <Image
            h="200px"
            w="210px"
            objectFit="cover"
            borderRadius="16px"
            src={trusteesboard.image}
            alt={trusteesboard.title}
            // onError={(e) => {
            //   console.error('Image failed to load:', e.target.src);
            //   e.target.src = '/path/to/placeholder/image.jpg'; // Путь к заглушке изображения
            // }}
          />
          <Box fontSize="xl" my="2rem">
            <RichText text={trusteesboard.description} />
          </Box>
          <Box mb="8rem" color="grey.100">
            <RichText text={trusteesboard.biography} />
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default Announcements;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;




//previus version with usePrismicAboutUs.js
// import React, {useMemo} from 'react'
// import { useQueryParam, StringParam } from "use-query-params";
// import { Container, Heading, Box, Image } from '@chakra-ui/react'
// import {graphql} from 'gatsby'
// import Main from '../../components/layouts/Main'
// import usePrismicAboutUs from "../../hooks/usePrismicAboutUs";
// import RichText from "../../components/PrismicRichText"

// const Announcements = () => {
//     const [id] = useQueryParam('id', StringParam)
//     const data = usePrismicAboutUs();
//     const trusteesborads = useMemo(() => data.trusteesborads.find(item => item.id === id), [id])
//     console.log("Trusteesboard Url", trusteesborads.img)
//     return(
//         <Main>
//             <Box overflow="hidden">
//                 <Container
//                     px="5"
//                     maxW="7xl">
//                     <Heading
//                         as="h1"
//                         fontSize={{base:'2xl', sm: "3xl"}}
//                         fontWeight="900"
//                         mt={{base: '3', sm: "5"}}
//                         mb={{base: '1.875rem', sm: "10"}}>
//                         {trusteesborads?.title}
//                     </Heading>
//                     <Image
//                       h="200px"
//                       w="210px"
//                       objectFit="cover"
//                       borderRadius="16px"
//                       src={trusteesborads?.image}
//                     />
//                     <Box fontSize="xl" my="2rem"><RichText text={trusteesborads?.description}/></Box>
//                     <Box mb="8rem" color="grey.100"><RichText text={trusteesborads?.biography}/></Box>
//                 </Container>
//             </Box>
//         </Main>
//     )
// }

// export default Announcements

// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `